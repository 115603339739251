import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Chart } from 'react-chartjs-2';
import { getIssueRiskKPI } from '../../redux/DashboardCRUD';


const ChartIssueRisk: React.FC<any> = ({ filter, modal }) => {
  const { t } = useTranslation();
  const [dataKPI, setDataKPI] = useState([0, 0, 0, 0]);

  useEffect(() => {
    if (filter) {
      getIssueRiskKPI(filter)
        .then((res: any) => {
          const valores = res.data.map(d => d);
          const noCero = valores.filter(v => v !== '0');

          if (noCero.length) {
            setDataKPI(res.data);
          }
          else {
            setDataKPI(null);
          }
        })
    }
  }, [filter])

  return (
    <div className={`card`}>
      <div className='card-header border-0 pt-5' style={{ minHeight: '40px' }}>
        <label className='fw-bolder mx-auto' style={{ fontSize: modal ? '20px' : '12px', marginBottom: modal ? '1.25rem' : '' }}>{t('incidentRisk')}</label>
      </div>

      <div className='card-body mb-0 pt-0' style={{ height: modal ? '450px' : '200px' }}>
        {
          dataKPI ?
            <Chart
              type='doughnut'
              data={{
                labels: [
                  t('high').toUpperCase(),
                  t('medium').toUpperCase(),
                  t('low').toUpperCase(),
                  t('normal').toUpperCase()
                ],
                datasets: [{
                  data: dataKPI,
                  backgroundColor: [
                    'rgb(255,0,0)',
                    'rgb(255,128,0)',
                    'rgb(255,255,0)',
                    'rgb(0,143,57)'
                  ],
                  hoverBackgroundColor: [
                    'rgb(255,0,0)',
                    'rgb(255,128,0)',
                    'rgb(255,255,0)',

                  ],

                }]
              }}
              options={{
                maintainAspectRatio: false,

                plugins: {
                  legend: {
                    position: 'bottom', labels: { font: { size: modal ? 20 : 10 }, padding: 9, boxWidth: 12 }
                  }
                }
              }}
            />
            : <div style={{ marginTop: '25%' }}>
              <h5 className='text-center'>{t('noData')}</h5>
            </div>
        }
      </div>
    </div>
  )
}

export { ChartIssueRisk };
