import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap-v5";
import { calcCrow } from "./helper";

type Props = {
    open: boolean;
    images: any[];
    structureSelected: any;
    onUpdateImages: (images) => void;
    onClose: () => void;
}
const ModalLinkImage = (props: Props) => {
    const { open, images, structureSelected, onUpdateImages, onClose } = props;

    const [openModalImages, setOpenModalImages] = useState(open);
    const [imagesSelectionList, setImagesSelectionList] = useState([]);

    useEffect(() => {
      setOpenModalImages(open);
    }, [open]);
    
    const handleSelectImages = () => {
        if (imagesSelectionList && structureSelected) {
            imagesSelectionList.forEach(image => {
                const index = images.findIndex(ii => ii.imageId === image.imageId);
                if (index !== -1) {
                    const imageSelected = images[index];
                    imageSelected.structureCode = structureSelected.description;
                    
                    // se calcula la nueva distancia a la torre elegida
                    const d = calcCrow(image.latitude, image.longitude, parseFloat(structureSelected.latitude), parseFloat(structureSelected.longitude));
                    imageSelected.distance = (d*1000).toFixed(2);

                    images[index] = imageSelected;
                    onUpdateImages(images);
                }
            })
        }
        onClose();
        setImagesSelectionList([]);
    }

    return(
        <Modal show={openModalImages} className={"modal-info"} size="lg" centered onHide={() => onClose()} >
            <Modal.Header>
                <Modal.Title>Selección de imágenes</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="d-flex flex-row flex-wrap">
                {
                    images && images.some(image => !image.structureCode)
                    ?
                    images
                        .filter(image => !image.structureCode)
                        .map((image, index) => (
                            <div key={index} className="image-item">
                                <div className="d-flex flex-column">
                                    <div className="d-flex flex-column">
                                        <input type="checkbox" onChange={() => {
                                            const images = imagesSelectionList;
                                            images.push(image);
                                            setImagesSelectionList(images);
                                        }} />
                                        <img src={image['data_url']} alt="" width="200" height="150" />
                                    </div>
                                </div>
                                <div className="image-item__btn-wrapper">
                                </div>
                            </div>
                    ))
                    : <h6 className="text-center text-muted">No hay imágenes pendientes de vincular</h6>
                    
                }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-secondary btn-sm"  onClick={() => onClose()}>
                    Cancelar
                </button>
                <button className="btn btn-primary btn-sm" onClick={() => handleSelectImages()}>
                    Confirmar
                </button>{" "}
            </Modal.Footer>
        </Modal>
    )
}
export default ModalLinkImage;