import axios from 'axios'

const { REACT_APP_API_URL } = process.env
export const UPLOADS = `${REACT_APP_API_URL}/upload-data/`
export const MULTIMEDIA = `${REACT_APP_API_URL}/multimedia/`
export const ISSUE = `${REACT_APP_API_URL}/issue/`
export const LINE = `${REACT_APP_API_URL}/line/`

export function uploadStructures(data) {
  return axios.post<{result: any}>(UPLOADS + 'structure', data, { headers: {"Content-Type": "multipart/mixed"} });
}

export function uploadIssues(data) {
  return axios.post<{result: any}>(UPLOADS + 'issues', data, { headers: {"Content-Type": "multipart/mixed"} });
}

export function uploadImages(data) {
  return axios.post<{result: any}>(MULTIMEDIA + 'upload-photo', data, { headers: {"Content-Type": "multipart/mixed"}, responseType: 'blob' });
}

export function uploadVideos(data) {
  return axios.post<{result: any}>(MULTIMEDIA + 'upload-video', data, { headers: {"Content-Type": "multipart/mixed"}, responseType: 'blob' });
}

export function listFilesMedia(nextToken, pageSize, folder) {
  const body = {
    NextContinuationToken: nextToken,
    MaxKeys: pageSize,
    Prefix: folder
  };
  return axios.post<{result: any}>(MULTIMEDIA + 'list-files', body);
}

export function downloadFile(filename: string) {
  return axios.get<{result: any}>(MULTIMEDIA + 'download-file/' + filename);
}

export function deleteMedia(filename: string) {
  return axios.delete<{result: any}>(MULTIMEDIA + 'delete-file/' + filename);
}

export function createRevisionPlan(body) {
   return axios.post(ISSUE + 'create-revision-plan', body);
}

export function createRevisions(body) {
  return axios.post(ISSUE + 'create-revisions', body);
}

export function editRevisions(body) {
  return axios.post(ISSUE + 'edit-revisions', body);
}

export function uploadMultipleFiles(body) {
  return axios.post(MULTIMEDIA + 'upload-multiple-files', body);
}

export function getImagesByRevisionPlan(revisionPlan) {
  return axios.get(MULTIMEDIA + 'get-images-by-revision-plan?revisionPlanId=' + revisionPlan);
}

export function getImagesByRevisionPlanAndTower(revisionPlanId, towerId) {
  return axios.get<{result: any}>(`${MULTIMEDIA}get-images-tower-revision?revisionPlanId=${revisionPlanId}&towerId=${towerId}`);
}

export function getRevisionNumberForLine(lineId) {
  return axios.get<{result: any}>(`${LINE}get-revision-number?lineId=${lineId}`);
}

export function getLinesWithRevision() {
  return axios.get<{result: any}>(`${LINE}get-lines-with-revision`);
}

export function deleteFile(revisionPlan, name) {
  return axios.delete(MULTIMEDIA + `/delete-file/${revisionPlan}/${name}`);
}
