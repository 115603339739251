import React, { useEffect, useState } from 'react'
import { Alert, Carousel } from 'react-bootstrap-v5';
import { useTranslation } from 'react-i18next';
import ModalImage from "react-modal-image";
import Moment from 'react-moment';
import { getImagesByRevisionPlanAndTower, listFilesMedia } from '../../uploads/redux/UploadsCRUD';

const RevisionDetails: React.FC<any> = ({ revisions, tower }) => {

  const [index, setIndex] = useState(0);
  const [imagesRevision, setImagesRevision] = useState([]);
  const [tokensPages, setTokensPages] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);

  useEffect(() => {
    // setIndex(0);
    const rev = revisions[0];
    getImagesRevision(rev);
  }, [revisions]);

  const { t } = useTranslation();

  // const getTokenIndex = (index) => {
  //   if(index === 0) {
  //       return '';
  //   }
  //   const page = tokensPages.filter(t => t.page === index);
  //   if(page.length) {
  //       return page[0].token;
  //   }
  //   return '';
  // }

  // const updateTokensList = (page, token) => {
  //   const tokens = [...tokensPages];
  //   tokens.push({ page, token });
  //   setTokensPages(tokens);
  // }

  // const listMediaFiles = (page, folder) => {
  //   listFilesMedia(getTokenIndex(page), 4, folder)
  //   .then((res: any) => {
  //       if(res && res.data){
  //           setPageIndex(page);
  //           setImagesRevision(res.data.Contents);
  //           updateTokensList(page+1, res.data.NextContinuationToken);
  //       }
  //   });
  // }

  const updateIndex = (direction) => {
    let nextIndex;
    if (direction === 'prev') {
      nextIndex = index === 0 ? revisions.length - 1 : index - 1;
    }
    else {
      nextIndex = index === revisions.length - 1 ? 0 : index + 1;
    }
    setIndex(nextIndex);

    const rev = revisions[nextIndex];
    getImagesRevision(rev);
  }

  const getImagesRevision = (rev) => {
    getImagesByRevisionPlanAndTower(rev.revision_plan_id, tower.id)
    .then((imagesUrlResponse: any) => {
      if (imagesUrlResponse) {
        setImagesRevision(imagesUrlResponse.data);
      }
    })
  }

  // const listImagesRevision = (rev, page) => {
  //   if (rev) {
  //     const folder = `${rev.revision_plan_id}/`
  //     listMediaFiles(page, folder);
  //   }
  // }

  const getNameCondition = (condition) => {
    switch (condition) {
      case 'LOW_RISK':
        return 'BAJO';
      case 'MEDIUM_RISK':
        return 'MEDIO';
      case 'HIGH_RISK':
        return 'ALTO';
      default:
        return 'NORMAL';
    }
  }

  const getColor = (condition) => {
    switch (condition) {
      case 'HIGH_RISK':
        return 'red';
      case 'MEDIUM_RISK':
        return 'orange';
      case 'LOW_RISK':
        return 'yellow';
      default:
        return 'green';
    }
  }
  const getColorForIssue = (issues,type) => {
debugger;// ver acaaaaaaa
      let highestRisk = "NORMAL";
    const unrepairedIssues = issues.filter(issue => issue.issue_status !== "REPAIRED");
    for (const issue of unrepairedIssues) {
      const currentRiskLevel = issue.risk_level;
      if (getRiskLevelOrder(highestRisk) < getRiskLevelOrder(currentRiskLevel)) {
        highestRisk = currentRiskLevel;
        if(type=='color'){

         return getColor(highestRisk)
        }else{

          return getNameCondition(highestRisk)
        }
      }else{
        if(type=='color'){

          return getColor(highestRisk)
        }else{

          return getNameCondition(highestRisk)
        }
      }
    }

  }

  function getRiskLevelOrder(riskLevel) {
    const riskOrder = {
      "HIGH_RISK": 3,
      "MEDIUM_RISK": 2,
      "LOW_RISK": 1,
        "NORMAL": 0
    };
    return riskOrder[riskLevel] || 0; // Default to 0 for unknown risk levels
  }
  const getIssueStatus = status => {
    switch(status) {
      case 'REPAIRED': return t('repaired').toUpperCase();
      case 'NOT_REPAIRED': return t('notRepaired').toUpperCase();
      case 'SCHEDULED': return t('scheduled').toUpperCase();
    }
  }

  return (
    <Carousel className='revosion-details p-0'
      activeIndex={index}
      controls={false}
      interval={null}
      indicators={false}>
      {
        revisions.map((lastRevision, index) => (

          <Carousel.Item key={lastRevision.id} className='p-0'>
            <Alert variant="secondary" className="mb-0 p-2">
              <div className="text-center">
                <h5>
                  {t('revision').toUpperCase()}
                  <span className="alert-link"> N°{lastRevision.number} - <Moment format={"DD/MM/YYYY"}>{lastRevision.date}</Moment></span>
                </h5>
              </div>
              {
                revisions.length === 1 ? null :
                  <div>
                    <button className="carousel-control-prev" onClick={() => updateIndex('prev')}>
                      <span className="carousel-control-prev-icon"></span>
                    </button>
                    <button className="carousel-control-next" onClick={() => updateIndex('next')}>
                      <span className="carousel-control-next-icon"></span>
                    </button>
                  </div>
              }
            </Alert>
            <Alert variant="secondary" className="mb-0 p-2">
              <div className='d-flex justify-content-between'>
                <div>{t('levelRisk')}.</div>
                {/*<div className='p-0 m-0' style={{ backgroundColor: getColor(issue.issueCode && issue.issue_status !== 'REPAIRED' ? issue.issueCode.level_risk : "-"), width: '1%' }}></div>*/}

                {/*<div style={{ backgroundColor: getColorForIssue(lastRevision.issues), width: '20%' }}></div>*/}
                <div className='fw-bold' style={{ backgroundColor: getColorForIssue(lastRevision.issues, 'color'), width: '20%' }}> </div>
                <div className='fw-bold'>{getColorForIssue(lastRevision.issues,'name')}</div>
              </div>
            </Alert>
            <hr className='my-1' />
            <p className="text-center my-auto">Incidencias.</p>
            {
              !lastRevision.issues || !lastRevision.issues.length ?
                <Alert variant="success">
                  <h5 className='text-center'>{t('noIncidents').toUpperCase()}</h5>
                </Alert> :
                <React.Fragment>
                  {/* <Carousel
                    key={'issues'}
                    activeIndex={indexIssue}
                    controls={false}
                    interval={null}
                    indicators={false}> */}
                    {
                      lastRevision.issues.map((issue, indexIssue) => {
                        return (
                          // <Carousel.Item key={issue.id} style={{ paddingRight: '5px' }}>
                            <div key={issue.id}>
                              {/* <Alert variant="secondary">
                                <div className="text-center">
                                  <h5>
                                    <i className="fa fa-circle mr-2 ml-2" style={{ color: getColor(issue.risk_level) }}></i>
                                    <span>{t('incident').toUpperCase()}</span>
                                    <span className="alert-link"> N°{indexIssue+1}/{lastRevision.issues.length}</span>
                                  </h5>
                                </div>

                                <h4 className="text-center mb-0">
                                  {issue.status === 'REPAIRED' ?
                                    <span data-toggle="tooltip" title={issue.resolutionComment}>{t('resolved')} <span className="fa fa-info-circle"></span> </span>
                                    : null}
                                </h4>
                                {
                                  lastRevision.issues.length === 1 ? null :
                                    <div>
                                      <button className="carousel-control-prev" onClick={() => updateIndexIssue('prev', lastRevision.issues)}>
                                        <span className="carousel-control-prev-icon"></span>
                                      </button>
                                      <button className="carousel-control-next" onClick={() => updateIndexIssue('next', lastRevision.issues)}>
                                        <span className="carousel-control-next-icon"></span>
                                      </button>
                                    </div>
                                }
                              </Alert> */}

                              <div className='d-flex justify-content-between'>
                                <div>{issue.dateFormatted}</div>
                                <div className='fw-bold'>{issue.statusDescription}</div>
                              </div>

                              {/* <div className='d-flex flex-column ps-1'>

                                <div style={{ marginBottom: '10px' }}>
                                  <ModalImage
                                    small={obtenerUrlImagen(issue)}
                                    large={obtenerUrlImagen(issue)}
                                    alt={issue.observations} hideZoom={false}
                                  />
                                </div>
                                <Player
                                  open={isOpenVideo}
                                  toggleModal={() => setIsOpenVideo(!isOpenVideo)}
                                  url={obtenerUrlVideo(lastRevision)}
                                />
                                <button className='btn btn-sm btn-danger'
                                  type="button"
                                  disabled={!lastRevision.video_name}
                                  onClick={() => setIsOpenVideo(!isOpenVideo)}
                                >
                                  <i className="fa fa-play" /> {t('playVideo')}
                                </button>

                              </div> */}
                              <Alert variant="secondary" className="mb-1 p-2">

                                <div className="d-flex flex-row justify-content-between">

                                  <div className='p-0 m-0' style={{ backgroundColor: getColor(issue.issueCode && issue.issue_status !== 'REPAIRED' ? issue.issueCode.level_risk : "-"), width: '1%' }}></div>
                                  <div className=' p-0 m-0 d-flex flex-column justify-content-start flex-wrap' style={{maxWidth: '150px'}}>
                                    <div>{t('state')}</div>
                                    <div className='fw-bold'>{issue.issue_status ? getIssueStatus(issue.issue_status) : "-"}</div>
                                  </div>
                                  <div className='d-flex flex-column justify-content-start flex-wrap' style={{maxWidth: '150px'}}>
                                    <div>{t('revisionObs')}</div>
                                    <div className='fw-bold'>{issue.observations ? issue.observations : "-"}</div>
                                  </div>
                                  <div className='d-flex flex-column justify-content-start flex-wrap' style={{maxWidth: '150px'}}>
                                    <div>{t('resolutionObs')}</div>
                                    <div className='fw-bold'>{issue.resolutionComment ? issue.resolutionComment : "-"}</div>
                                  </div>
                                  <div className='d-flex flex-column justify-content-start flex-wrap' style={{maxWidth: '150px'}}>
                                    <div>{t('type')}</div>
                                    <div className='fw-bold'>{issue.issueCode ? issue.issueCode.description : "-"}</div>
                                  </div>
                                  <div className='d-flex flex-column justify-content-start flex-wrap'>
                                    <div>{t('code')}</div>
                                    <div className='fw-bold'>{issue.issueCode ? issue.issueCode.code_name : "-"}
                                </div>
                                  </div>
                                  <div className='d-flex flex-column justify-content-start flex-wrap'>

                                    <div>{t('videoTime')}</div>
                                    <div className='fw-bold'>{issue.video_time ? issue.video_time : "-"}</div>
                                  </div>
                                </div>
                              </Alert>
                            </div>
                          // </Carousel.Item>
                        )
                      })
                    }
                  {/* </Carousel> */}
                </React.Fragment>
            }
            <hr />
            <p className="text-center my-auto">Imagenes de la revisión </p>
            <div className="d-flex flex-row images-scroll">
              {
                imagesRevision && imagesRevision.map(image => (
                  <ModalImage small={image} large={image} className="w-200px h-150px image-item" alt={image} />
                ))
              }
              {
                !imagesRevision.length &&
                  <p className="text-center mx-auto text-muted mt-10">No hay imagenes para mostrar</p>
              }
            </div>
            {/* {
                  tokensPages.length ? 
                  <div className="d-flex justify-content-center">
                      {
                          pageIndex !== 0 ? 
                          <button className="btn btn-secondary btn-sm me-5" onClick={() => {
                              listImagesRevision(lastRevision, pageIndex-1);
                          }}>
                              <div className="fa fa-arrow-left pe-3"></div>
                              Anterior</button>
                          : <></>
                      }
                      {
                          getTokenIndex(pageIndex+1) ? 
                          <button className="btn btn-secondary btn-sm" onClick={() => {
                            listImagesRevision(lastRevision, pageIndex+1);
                          }}>
                              Siguiente
                              <div className="fa fa-arrow-right ps-3"></div>
                          </button>
                          : <></>
                      }
                      
                  </div> : <></>
              } */}
          </Carousel.Item>
        ))
      }
    </Carousel>
  )
}

export { RevisionDetails }
