import { Formik } from "formik";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap-v5";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

type Props = {
    open: boolean;
    onClose: () => void;
    onConfirm: (data) => void;
    data?: any;
    issueCodes: any[];
}
const ModalCreateIssue = (props: Props) => {
    const { open, onClose, onConfirm, data, issueCodes } = props;

    const [openModal, setOpenModal] = useState(open);
    const [issueData, setIssueData] = useState<any>();
    
    const { t } = useTranslation();

    useEffect(() => {
      setOpenModal(open);
    }, [open]);

    useEffect(() => {
        if (data && data.issue_code && issueCodes) {
            const issueCodeSelected = issueCodes.filter(ic => ic.code_name === data.issue_code);
            if (issueCodeSelected.length) {
                const issueData = {
                    ...data,
                    issue_code_id: issueCodeSelected[0].id,
                }
                setIssueData(issueData);
            }
        } else {
            setIssueData({
                issue_number: data.issue_number || 1,
                issue_code_id: '',
                repair_date: '',
                issue_status: 'NOT_REPAIRED',
            })
        }
      }, [data, issueCodes]);

    const IssueSchema = Yup.object().shape({
        issueNumber: Yup.number().required(t('campoRequerido')),
        issueCodeId: Yup.string().required(t('campoRequerido')),
        status: Yup.string().optional().oneOf(['REPAIRED', 'NOT_REPAIRED']),
        resolutionDate: Yup.date().when('status', {
            is: 'REPAIRED',
            then: Yup.date().required(t('campoRequerido'))
        }),
        observations: Yup.string().max(255).optional(),
        resolution_comment: Yup.string().max(1000).optional(),
    });
    
    if (!issueData) return null;
    return(
        <Formik
            initialValues={{
                issueNumber: issueData?.issue_number || 1,
                issueCodeId: issueData?.issue_code_id || '',
                resolutionDate: issueData?.repair_date || '',
                status: issueData?.issue_status || 'NOT_REPAIRED',
                rev_observations: issueData?.rev_observations || '',
                resolution_comment: issueData?.resolution_comment || '',
            }}
            validationSchema={IssueSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                const result: any = { 
                    ...data,
                    ...values 
                };
                result.issueCode = issueCodes.filter(ic => ic.id === values.issueCodeId)[0];

                onConfirm(result);
                setSubmitting(false);
            }}
            enableReinitialize={true}
        >
            {({ values, isSubmitting, handleChange, handleSubmit, isValid }) => (
         
                <Modal show={openModal} className={"modal-info"} size="lg" centered onHide={() => onClose()} >
                    <Modal.Header>
                        <Modal.Title>Crear incidencia</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="d-flex flex-column flex-wrap">
                            <div className='form-group'>
                                <label htmlFor='issue-number'>{t('incidentNumber')}</label>
                                <input
                                    className='form-control'
                                    type="number"
                                    placeholder='99'
                                    name="issueNumber"
                                    value={values.issueNumber}
                                    onChange={handleChange}
                                    readOnly
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor={'incidentCode'}>
                                    {t('incidentCode')}
                                </label>
                                <select
                                    className="form-select"
                                    name='issueCodeId'
                                    value={values.issueCodeId}
                                    onChange={handleChange}
                                >
                                    <option>{t('selectOneOption')}</option>
                                    {
                                        issueCodes.map(issueCode => (
                                            <option key={`ic_${issueCode.id}`} value={issueCode.id}>{`${issueCode.code_name} - ${issueCode.description}`}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor={'status'}>
                                    {t('Estado')}
                                </label>
                                <select
                                    className="form-select"
                                    name='status'
                                    value={values.status}
                                    onChange={handleChange}
                                >
                                    <option>{t('selectOneOption')}</option>
                                    <option value="NOT_REPAIRED">{t('notRepaired')}</option>
                                    <option value="REPAIRED">{t('repaired')}</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor={'observations'}>
                                    {t('Observaciones')}
                                </label>
                                <textarea
                                    className="form-control"
                                    name='rev_observations'
                                    rows={2}
                                    maxLength={255}
                                    value={values.rev_observations}
                                    onChange={handleChange}
                                >
                                </textarea>
                            </div>
                            {
                                values && values.status === 'REPAIRED' &&
                                <>
                                <div className='form-group'>
                                    <label htmlFor='start-date'>{t('repairDate')}</label>
                                    <input
                                        className='form-control'
                                        type="date"
                                        placeholder='dd/mm/aaaa'
                                        name="resolutionDate"
                                        value={values.resolutionDate}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor={'resolutionObs'}>
                                        {t('resolutionObs')}
                                    </label>
                                    <textarea
                                        className="form-control"
                                        name='resolution_comment'
                                        rows={3}
                                        maxLength={1000}
                                        value={values.resolution_comment}
                                        onChange={handleChange}
                                    >
                                    </textarea>
                                </div>
                                </>
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-secondary btn-sm"  onClick={() => onClose()}>
                            {t('cancel')}
                        </button>
                        <button type="button" className="btn btn-primary btn-sm" onClick={() => handleSubmit()} disabled={!isValid}>
                            {t('confirm')}
                        </button>{" "}
                    </Modal.Footer>
                </Modal>
            )}
            </Formik>
    )
}
export default ModalCreateIssue;