import React, {FC, useState} from 'react'
import { useTranslation } from 'react-i18next'
import Moment from 'react-moment'
import { Charts } from './components/Charts'
import { Table } from './components/Table'
// const DashboardWrapper: FC = () => {
//   const { t } = useTranslation();
//   const años = Array.from({length: 5}, (_, i) => new Date().getFullYear() - i);
//   const [añoSeleccionado, setAñoSeleccionado] = useState(años[0]); // Primer elemento de la lista de años
//   const [filtro, setFiltro] = useState({
//     fechaInicio: `${añoSeleccionado}-01-01`,
//     fechaFin: `${añoSeleccionado}-12-31`,
//     año: añoSeleccionado,
//   });
//
//   const handleCambiarAño = (año) => {
//     setAñoSeleccionado(año);
//     setFiltro({
//       año,
//       fechaInicio: `${año}-01-01`,
//       fechaFin: `${año}-12-31`,
//     });
//   };
//
//   return (
//     <>
//       <div className='card-header'>
//         <div className='d-flex flex-row justify-content-between'>
//           <label className='fs-3'>
//             {t('performanceIndicatorsBetween')}{" "}
//             <strong>
//               <Moment format={"DD/MM/YYYY"}>{filtro.fechaInicio}</Moment>{" "}-{" "}
//               <Moment format={"DD/MM/YYYY"}>{filtro.fechaFin}</Moment>
//             </strong>
//           </label>
//           <div>
//             <select value={añoSeleccionado} onChange={(e) => handleCambiarAño(e.target.value)}>
//               {años.map((año) => (
//                 <option key={año} value={año} selected={año === añoSeleccionado}>{año}</option>
//               ))}
//             </select>
//           </div>
//         </div>
//       </div>
//       <Charts filtro={filtro}></Charts>
//       <Table filtro={filtro}></Table>
//     </>
//   )
// };



const DashboardWrapper: FC = () => {
   const year = Array.from({length: 5}, (_, i) => new Date().getFullYear() - i);
  const { t } = useTranslation();
  const [filtro, setFiltro] = useState({
    date_start: `${year[0]}-01-01`,
    date_end: `${year[0]}-12-31`,
    anio: year[0]
  });

  const handleCambiarAño = (anio) => {
    setFiltro({
      anio,
      date_start: `${anio}-01-01`,
      date_end: `${anio}-12-31`
    })
  }

  return (
    <>
      <div className='card-header'>
        <div className='d-flex flex-row justify-content-between'>
          <label className='fs-3'>
            {t('performanceIndicatorsBetween')}{" "}
              <strong>
                <Moment format={"DD/MM/YYYY"}>{filtro.date_start}</Moment>{" "}-{" "}
                <Moment format={"DD/MM/YYYY"}>{filtro.date_end}</Moment>
              </strong>
          </label>
          <div>
            {year.map(anio => {
              return (
                <button key={anio} type="button" className={"btn btn-sm btn-" + (filtro.anio === anio ? "primary" : "secondary")} onClick={() => handleCambiarAño(anio)}>
                  {anio}
                </button>
              )
            })}
          </div>
        </div>
      </div>
      <Charts filtro={filtro}></Charts>
      <Table filtro={filtro}></Table>
    </>
  )
}

export default DashboardWrapper
